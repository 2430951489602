<template>
  <fieldsTds
    v-if="type == 'LDAP' || type == 'SSH'"
    :fieldAttributes="localFieldAttrs"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
    :value="value"
  ></fieldsTds>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
export default {
  components: { fieldsTds },
  props: ["result", "fieldAttributes", "field","value"],
  computed: {
    type() {
      return this.result.valueLine.c_type;
    },
    localFieldAttrs() {
      let localFieldAttrs = {
        ...this.fieldAttributes,
        type: "text",
      };
      if (this.type == "SSH") {
        this.$set(localFieldAttrs, "fieldAttrInput", { class: "required" });
      }
      return localFieldAttrs;
    },
  },
};
</script>